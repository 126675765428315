@media screen and (min-width:768px){
	.section-sells .bloc-zoom-texte > .btn{
		padding-left:10px;
		padding-right:10px;
	}
	
	
}

@media screen and (min-width: 1201px){
	
	.navbar-collapse{
		flex-grow: 0;	
	}
	.section-sells .carousel-arrow,
	.section-categ .carousel-arrow,
	.section-leasing .carousel-arrow{
		display:none;
	}
	
	a.nav-link.select-language-item {
	    display: inline-block;
	    margin-top: -2px;
	}
	
	.text-subfooter p{
		margin-bottom:0;
	}
	
	.footer hr{
		margin:10px 0 20px 0;
	}
	
	
	
	.formSearch.fix-scroll{
		top:92px!important;	
	}
	
	
	.homepage .formSearch.fix-scroll{
		top:38px!important;	
	}
	
	
	.listing-bien{
		max-width: 530px;
		margin: 0 auto;
	}
	
	.listing-bien{
		.row-bien-prestations{
			border:none;	
			padding:0;
			margin:20px 0;
			
			& > div{
				padding-bottom:10px;
				margin-bottom:10px;
				border-bottom:1px solid @darkColor;
			}
		}
	
		.bien-prestation{
			font-size:1.7rem;
			margin:0;
		}
	}
	
	.listing-bien-separateur{
		margin: 0 15px;
	}
	
	.listing-bien-ville{
		margin-bottom:20px;
	}
	.carousel-listing-bien{
		height:350px;
		margin-bottom:20px;
		
		.carousel-control-prev, .carousel-control-next{
			opacity:1;
		}
		.carousel-control-prev-icon,
		.carousel-control-next-icon{
			background-color: #232323;
		    border-radius: 36px;
		    height: 35px;
		    width: 35px;
		    opacity: 1;
		    background-size: 50% 50%;	
		}
	}
	
	.carousel-bien{
		height:100vh;	
	}
	
	.logo-home{
		display:block;	
		flex: 1;
		& > img{
			max-height: 70px;
		}
	}
	
	.bien-exclusivite{
		padding-left: 20px;
	    padding-right: 20px;
	    margin: 8px 0;
	    height:46px;
	}

	.bloc-contact h2{
		margin-bottom:40px;
		
	}
	
	.row-infos-bien{
		border-bottom: 1px solid #282828;
		padding:10px 0 0;
	}
	
	.bloc-bien{
		border:1px solid @darkColor;
		max-width: 430px;
		margin: 20px 20px 80px;
		width: 100%;
	}
	
	.bien-prix{
		margin:0;
	}
	
	.bien-reference{
		text-align:right;
	}
	
	.row-bien-prestations{
		border-bottom: 1px solid #282828;
	
	}
	
	.bien-prestation{
		display: block;
	    margin: 0;
		    & > svg{
			width:40px;
			height:60px;
			vertical-align: middle;
		    margin-right: 20px;
		    }
	}
	
	.bien-titre{
		font-size:2.7rem;
		font-weight:600;
		margin-bottom: 0;
		margin-top: 16px;
	}
	
	.bien-prix{
		font-size:2.7rem;
		font-weight:300;
		color:#fff;
		background-color: @darkColor;
		padding:25px 50px;
		display:block;
	}
	
	.bloc-bien-textes{
		padding:20px;
	}
	
	.bloc-bien-img{
		width:100%;
		height:320px;
		overflow: hidden;
		position:relative;
		
		& > img{
			position: absolute;
		    left: 50%;
		    top: 50%;
		    transform: translate(-50%, -50%);	
		    max-height: 100%;
		}
	
	}
	
	.bloc-bien-titre{
		font-size:1.8rem;
		color:@mainColor;
		font-weight: 600;
		text-transform:uppercase;
	}
	
	.bloc-vus{
		max-width: 430px;
		margin: 20px 20px;	
		width: 100%;
	}
	
	.bloc-vus-img{
		width:100%;
		height:320px;
		overflow: hidden;
		position:relative;
		margin-bottom:20px;
		
		& > img{
			position: absolute;
		    left: 50%;
		    top: 50%;
		    transform: translate(-50%, -50%);	
		    max-height: 100%;
		}
	}
	
	.bloc-vus-titre{
		font-size:1.8rem;
		color:@mainColor;
		font-weight: 400;
		text-transform:uppercase;
	}
	
	.section-text {
		padding: 90px 40px;
	}

	.logo-home-bloc {
		position: absolute;
		text-align:center;
	    z-index: 1;
	    left: 0;
	    right:0;
	    top: 57px;
	    padding:20px;
	    
	    background-color:rgba(0,0,0,0);
	    
	    img{
	    	width: 410px;
	    }
	}
	
	.section-sells .bloc-zoom-texte > .btn{
		padding-left:50px;
		padding-right:50px;
	}
	
	.panoramique{
		height:500px;
	}
	
}

@media screen and (max-width: 1200px){
	.carousel-index .carousel-item > img, 
	.carousel-bien .carousel-item > img{
		max-width: 180%;
	    height: auto;
	    min-height: unset;
	    top: 49%;
	}
	
	.carousel-listing-bien .carousel-item > img{
		max-width: 180%;
	    height: auto;
	    min-height: 100%;
	    top: 49%;
	}	
	
	.logo-home{
		max-width: 200px;
	    position: absolute;
	    top: 0;
	    right: 10px;
	    z-index: 1000;
	    min-width: 192px;
	}
	a.nav-link.select-language-item {
	    display: inline-block;
	    margin: 0 10px;
	}
	
	.formSearch.fix-scroll{
		top:57px!important;	
	}
	
	
	.row-bien-prestations{
		padding:0;
		margin:20px 0;
	}
	
	
	.listing-bien-textes .bien-prestation{
		font-size:1.4rem;	
	}
	.listing-bien-textes a.btn.btn-tertiary {
	    width: max-content;
	    display: block;
	    margin: 10px auto;
	}

	.listing-bien-prix{
		display:inline-block;
		background-color: @darkColor;
		color:#fff;
		padding:5px 10px;
		margin:10px 0;
	}
	
	.listing-bien-ville, .listing-bien-categorie{
		font-size:1.8rem;
		display:inline-block;
	}
	
	.listing-bien-ville{
		display:block;
	}
	
	.listing-bien-reference{
		display:inline-block;
	}
	
	.carousel-listing-bien{
		height:260px;
		margin-bottom:20px;
	}
	
	.carousel-bien{
		height:calc(~"100vh - 57px");	
		//height:100vh;
	}
	
	.bien-prestation{
		margin:5px 0;
	}
	.bien-exclusivite{
		margin:0;
		height: fit-content;
	}
	
	.bien-titre{
		margin-top:0;
	}
	
	.row-infos-bien{
		border-bottom: 1px solid #282828;
		padding:10px 0 0;
	}
	
	.bien-prix{
		margin:20px 0;	
	}
	
	.bloc-bien, .bloc-vus{
		margin: 20px 20px 50px;
	}
	.dropdown-menu {
		&.megamenu{
		    column-count: unset;
		    column-gap: unset;
		    overflow: scroll;
    		height: 300px;
		}
	    margin-left: -30px;
	    margin-right: -30px;
	    padding: 30px;
	    background-color: #fff;
	}
	
	.header{
		background: rgba(0, 0, 0, 1);
	}
	
	.carousel-index {
	    //height: calc(~"100vh - 57px");
	    height:100vh;
	}
	
	.carousel-indicators{
		display:none;
	}
	
	
	.logo-home-bloc {
		position: absolute;
		text-align:center;
	    z-index: 1;
	    left: 0;
	    right:0;
	    top: 57px;
	    padding:20px;
	    
	    background-color:rgba(0,0,0,0.5);
	    
	    img{
	    	width: 310px;
	    }
	}
	
	
	
	.formSearchBlocAcheter, .formSearchBlocLouer{
		width:100%;
		margin:0;
		max-width: none;
	}
}

@media screen and (max-width: 992px){
	
	.carousel-index .carousel-item > img, 
	.carousel-listing-bien .carousel-item > img{
		max-width: 200%;
	    height: auto;
	    min-height: unset;
	    top:53%;
	}
	
		.carousel-bien .carousel-item > img, 
	.carousel-listing-bien .carousel-item > img {
	    max-width: 131%;
    height: auto;
    min-height: unset;
    top: 54%;
	}
		
		
		
	.titre-bloc-couleur {
		font-size: 2rem;	
	}
	
	.carousel-listing-bien{
		height:350px;
	}
	
	.listing-bien-ville, .listing-bien-categorie{
		font-size:1.7rem;
		display:block;
	}
	
	.listing-bien-reference{
		display:block;
	}
	
	.carousel-bien{
		height:600px;	
	}
	
	.logo-home-bloc img{
		height:70px;
	}
	
	.bloc-bien, .bloc-vus{
		width:60%;
		margin-right:auto;
		margin-left:auto;
	}
	
	
	.bien-exclusivite{
		margin: 10px auto;
	}
	
	.bien-titre{
		font-size:2.7rem;
		margin:10px 0;
	}

	
	.row-infos-bien{
		border-bottom: 0;
		padding:0;
	}
	
	
	
	.bloc-bien-img, .bloc-vus-img{
		    height: 228px;
	}
	
	.bien-prestation{
		margin:10px 0;	
	}
	
	.bien-prix{
		margin:20px auto 0;
	}

	.carousel-index {
	    //height: calc(~"100vh - 57px");
	    height: 100vh;
	}


	.svgMap {
	    position: relative;
	    width: 100%;
	    margin-bottom: 40px;
	}
	
	.bloc-titre {
    	font-size: 2.2rem;
	}
	
	.formSearchBlocAcheter, .formSearchBlocLouer{
		background-color: #000;
	}
	

	footer{
		text-align:center;
	}

	
	h2{
		font-size:2.5rem;
	}


}

@media screen and (max-width: 768px){
	
	a.btn.btn-carousel-contact{
		    width: auto;
		
	}
	
	
	.carousel-index .carousel-item > img, 
	.carousel-listing-bien .carousel-item > img{
		max-width: 110%;
	    height: auto;
	    min-height: unset;
	    top:56%;
	}
	
	.carousel-bien .carousel-item > img, 
	.carousel-listing-bien .carousel-item > img {
	    max-width: 132%;
	    height: auto;
	    min-height: unset;
	    top: 47%;
	}


		
	.homepage .logo-home{
		display:block;
	}
	
	.logo-home-bloc{
		display:none;
	}

	.titre-bloc-couleur{
		font-size: 2.8rem;	
	}
	.animated{
		animation: none;
	}
	
	.listing-bien-prix{
		display:inline-block;
		background-color: @darkColor;
		color:#fff;
		padding:5px 10px;
		margin:10px 0;
	}
	
	.listing-bien-ville, .listing-bien-categorie{
		font-size:1.7rem;
		display:block;
	}

	.listing-bien-textes a.btn.btn-tertiary{
		width: max-content;
	    display: block;
	    margin: 10px auto;
	}

	
	.carousel-listing-bien{
		height:260px;
	}
	
	.listing-bien-reference{
		display:inline;
		font-size:1.4rem;
	}
	
	
	
	.carousel-bien{
		height:400px;	
	}
	
	
	.bien-titre{
		font-size:2rem;
		font-weight:600;
		margin-bottom: 6px;
		margin-top: 6px;
	}

	.formSearch{
		width: 100%;
	    margin: 0;
	    position: relative;
	    margin-left: unset;
	    left: auto;
	}
	
	.btn-tertiary{
		margin-bottom:5px;
	}
	
	
	.nav-footer a {
	    font-size: 1.1rem;
	}
	.form-inline input.form-control {
	    width: 100%;
	     display: block; 
	}
	
	.form-inline{
		display:block;
	}
	
	.bloc-titre {
    	font-size: 2.5rem;
	}
	.bloc-zoom-image-scale > img{
		margin-top:-26px;
	}
	
	.section-sells .bloc-zoom-image-scale > img{
		margin-top:-70px;
	}
	
	a.btn, button.btn,
	.form-inline button.form-control.btn{
	    width: 100%;
	    display: block;
	}

	[data-carousel]{
		& .bloc-zoom{
			margin:0;
			
			&:after, &:before{
				content:none;
			}
		}
		& .bloc-zoom-texte{
			padding: 100px 48px 0;
			
			& > .btn{
				padding:12px 30px;
				opacity:1;
			}
		}
		
		.bloc-zoom.full:hover .bloc-zoom-texte,
		.bloc-zoom:hover .bloc-zoom-texte,
		.bloc-locations .bloc-zoom:hover .bloc-zoom-texte{
			padding-top: 100px;
		}
	}
	[data-carousel],
	.bloc-zoom,
	.bloc-zoom.full{
		height:300px;
	}

	

	// Blocs
	.section-sells,
	.section-categ,
	.section-leasing {
		
		.bloc-zoom,
		&.container,
		&.container-fluid {
			padding: 0;
		}	
		&.container,
		&.container-fluid {
			overflow: hidden;
			position:relative;
		}	
		.row { 
			flex-wrap: nowrap;
			margin: 0;
			transition: transform ease 0.2s;
		}
	}
	
	h2{font-size:2.2rem;}
	
	.carousel-index {
	    height: @heightCarouselMobile;
	}

	
	
	hr{
		margin:20px 0;
	}
	
	.bloc-paradis{
		padding:40px;
		height:auto;
	}	
}

@media screen and (max-width: 576px){
	
	.carousel-index .carousel-item > img, 
	.carousel-bien .carousel-item > img, 
	.carousel-listing-bien .carousel-item > img{
		max-width: 200%;
	    height: auto;
	    min-height: unset;
	    top: 40%;
	}
	
	.listing-bien-ville, .listing-bien-categorie{
		font-size:1.7rem;
		display:block;
	}
	
	.listing-bien-reference{
		display:block;
	}
}

@media screen and (max-width:472px){
	
	.carousel-index .carousel-item > img, 
	.carousel-listing-bien .carousel-item > img{
		max-width: 200%;
	    height: auto;
	    min-height: unset;
	    top: 58%;
	}
	
	.carousel-bien .carousel-item > img, 
	.carousel-listing-bien .carousel-item > img {
	    max-width: 156%;
	    height: auto;
	    min-height: unset;
	    top: 57%;
	}
	
	.section-sells .carousel-arrow, 
	.section-categ .carousel-arrow, 
	.section-leasing .carousel-arrow{
		bottom: 87px;
	}
	
	
	
	
	
	.formSearchEntreeTitre{
		font-size: 1.3rem;	
	}
	
	.formSearch.fix-scroll .formSearchEntree{
		padding-left: 20px;
		
		&:first{
			padding-left:20px;	
		}
	}
	
	.titre-bloc-couleur {
		font-size: 2rem;	
	}
	.listing-bien-textes a.btn.btn-tertiary{
		width:100%;
		display:block;
	}
	
	.carousel-listing-bien{
		height:190px;
	}
	
	
	
	
	.carousel-bien{
		height:300px;	
	}

	.bloc-vus, .bloc-bien{
		width:100%;
		margin-bottom: 0;
	}
	.bien-prestation{
		font-size:1.8rem;
	}
	
	.bien-vus{
		width:100%;
	}
	
	
	[data-carousel],
	.bloc-zoom,
	.bloc-zoom.full{
		height:200px;	
	}
	.bloc-zoom-image-scale > img {
	    margin-top: -78px;
	}
	
	.carousel-arrow{
	    bottom: 87px;
	}
	
	[data-carousel]{
		& .bloc-zoom{
			margin:0;
			
			&:after, &:before{
				content:none;
			}
		}
		& .bloc-zoom-texte{
			padding: 50px 48px 0;
			
			& > .btn{
				padding:12px 30px;
				opacity:1;
			}
		}
		
		.bloc-zoom.full:hover .bloc-zoom-texte,
		.bloc-zoom:hover .bloc-zoom-texte,
		.bloc-locations .bloc-zoom:hover .bloc-zoom-texte{
			padding-top: 50px;
		}
	}
	
}