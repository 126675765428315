/* INCLUSIONS **********************/
@import "_includes.less";

// Force l'ouverture des dropdown en HOVER (voir aussi dans assets/js/scripts.js)
.dropdown:hover>.dropdown-menu {
  //display: block;
}


/* TOOLS **********************/
html, body{
	margin:0;
	padding:0;
	font-size:@baseFontSize;
}

hr{
	border-color:rgba(255,255,255,0.4);
	margin: 40px 0;
	width: 100%;
}

//*{border:1px solid red;}

/* FORM SEARCH **********************/

.formSearch{
	width: 580px;
    position: absolute;
    z-index: 1;
    bottom: 0;
    top:auto;
    left: 50%;
    margin-left: -290px;
    font-size: 0; // Empêche l'espacement entre deux inline-block;
}

.formSearchEntree{
	background-color:@darkColor;
	color:#fff;
	border:none;
	width: 50%;
	font-family: 'montserrat', Arial, sans-serif;
	font-size:1.7rem;
	font-weight: 300;
	height:66px;
	display:inline-block;
	cursor:pointer;
	position:relative;
	
	&[aria-expanded="true"] > span{
	    border-bottom: 1px solid #fff;
		padding-bottom: 5px;
	}
	
	&.first{
		border-right:1px inset darken(#fff,60%);	
	}
}

.formSearch.fix-scroll .formSearchEntree{
	height:45px;
}

.formSearchEntreeTitre {
    display: inline-block;
    padding-bottom: 5px;
    border: 1px solid rgba(0,0,0,0);
}

.svgLoupe{
	vertical-align: bottom;
	margin-right: 15px;
}

.formSearchBlocAcheter, .formSearchBlocLouer{

	background-color:rgba(30,30,30,0.3);
	padding:26px 14px;
	transition: all 200ms;
	
}

.formSearchButton{
	width: 270px;
    margin-top: 12px;
}


/* STYLES **********************/

.carousel-index, .carousel-bien, .carousel-listing-bien {
    position: relative;
    height: 100vh;
	
	.carousel-inner {
	    position: relative;
	    width: 100%;
	    overflow: hidden;
	    height: 100%;
	}
	.carousel-item {
	    height: 100%;
	    
	    & > img { 
		    min-width: 100%;
		    min-height: 100%;
		    position: absolute;
		    left: 50%;
		    top: 50%;
		    transform: translate(-50%, -50%);
		}
		
		&.contact::before{
			content:'';
			background-color: rgba(0,0,0,0.6);
			position:absolute;
			top:0;
			bottom:0;
			left:0;
			right:0;
			 z-index: 10;
		}
	}
	
	.carousel-arrow{
		display:none;
	}
}



.section-text{
	padding:40px;	
}

.text-souligne{
	margin-bottom:30px;
}

.bloc-ventes {
	padding:70px 0 100px;
}

.bloc-locations .bloc-zoom{
	height:330px;
	margin:20px 0;
	
	& .bloc-zoom-texte{
	//	padding-top:140px;
	}
	
	&:hover .bloc-zoom-texte{
		padding-top:120px;	
	}
}

.bloc-newsletter{
	padding:50px 30px;
	height:330px;
}

.bloc-paradis{
	padding:40px;
	height:310px;
}

.bloc-zoom{
	height:370px;
	
	&.full{
		width:auto;
		margin-top:0;
		height:310px;
		
		&:hover .bloc-zoom-texte{
			padding-top:100px;	
		}
	}
	
	position:relative;
	
	&:hover{
		.bloc-zoom-texte{
			padding-top:128px;
			a{opacity:1}
		}
		
		.bloc-zoom-image-scale{
			transform: scale(1.1);
		}
	}
	
	&:before, &:after{
		content: '';
	    position: absolute;
	}
	
	&.white:before, &.white:after{
		background-color: #fff;
	}	
	&.black:before, &.black:after{
		background-color: @darkColor;
	}
	&.h-d:before {
	    height: 10px;
	    width: 50%;
	    top: -10px;
	    right: 15px;
	}
	&.h-g:before {
	    height: 10px;
	    width: 50%;
	    top: -10px;
	    left: 15px;
	    
	}
	&.corner-b-d:before {
	    height: 10px;
	    width: 50%;
	    bottom: -10px;
	    right: 5px;
	}
	
	&.corner-b-d:after {
	    width: 10px;
	    height: 50%;
	    bottom: -10px;
	    right: 5px;
	    
	}
	
	
	&.corner-h-d:before {
	    height: 10px;
	    width: 50%;
	    top: -10px;
	    right: 15px;
	}
	
	&.corner-h-d:after {
	    width: 10px;
	    height: 30%;
	    top: -10px;
	    right: 5px;
	}
	
	&.corner-h-g:before { 
	    height: 10px;
	    width: 50%;
	    top: -10px;
	    left: 5px;
	}
	&.corner-h-g:after { 
	    height: 30%;
	    width: 10px;
	    top: -5px;
	    left: 5px;
	}
	
}

.bloc-zoom-image{
	overflow: hidden;
		
}

.bloc-zoom-image-scale{
	transition: all 1000ms;	
}

.bloc-zoom-texte {
    position: absolute;
    z-index: 3;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
	padding-top: 156px;
	transition: all 500ms;
	
	text-align:center;
	
	a{opacity:0;transition: all 400ms;}
	
	.full &{
		padding-top:130px;	
	}
}

.bloc-titre{
	font-size:3.6rem;
	font-weight:700;
	color:#fff;
}

.svgMap {
    position: relative;
    width: 100%;
    margin-bottom: 40px;
}

.svg-map-link{
	cursor:pointer;	
	fill-opacity:0;
	//stroke:blue;
}

.svg-text-list{
	padding:0 0 20px;
	margin:0;
	list-style:none;
	
	& > li{
		border-bottom:1px solid @mainColor;	
		
		&:last-child{
			border:none;
		}
	}
	
}

.bloc-zoom-image-scale > img {
    margin-top: 0;
}

.svg-text-link{
	color:@darkColor;	
	font-size:1.3rem;
	font-weight:600;
	padding: 10px 0;
    display: block;
}



.titre-newsletter{
	margin-bottom:0;
}


.svgMap{
	position: absolute;
    width: 771px;
}

.form-bloc-newsletter{
	padding:40px 0;
	
	&.form-inline input.form-control, &.form-inline button.form-control{
		width:100%;	
	}
}


.listing-bien-textes{
	margin-bottom:100px;
}

.footer{
	padding:30px 0;
}

.list-social{
	padding:30px;
}
.logo-footer{
	margin-bottom:30px;
}

.bt-valider-newsletter{
	margin-top:10px;
}

.logo-home{
    display: block;
    float: right;
    //max-height: 47px;
       margin: 8px 0;
    svg{
    	min-width:184px;
    	max-width: 290px;
    }
}

.homepage .logo-home{
	display:none;
}

.homepage .fix-scroll .logo-home{
	display:block;
}


.carousel-height{
	height:800px;
}

.carousel-indicators.vignettes{
	bottom: -10px;
	& > li{
		width:73px;
		height:73px;
		border:3px solid #e8e8e8;
		padding: 5px 2px;
		overflow: hidden;
		cursor:pointer;
		margin:0;
	
		& > img{
			position: absolute;
		    left: 50%;
		    top: 50%;
		    transform: translate(-50%, -50%);	
		    max-height: 100%;
		}
	}
}

.row-infos-bien{
	border-bottom: 1px solid #282828;
	padding-top: 20px;
	margin-bottom:20px;
}

.row-bien-prestations{
	margin-bottom: 40px;
	margin-top: 40px;
	padding-bottom: 40px;
}

.bien-prestation{
	display: block;
	    & > svg{
		width:40px;
		height:40px;
		vertical-align: middle;
	    margin-right: 20px;
	    }
}



.bien-dpe{
	.bien-prestation{
		color:@mainColor;
	}
	svg > path{
		fill:@mainColor!important;
	}
	
	&:hover{
		text-decoration:none;
	}
}
	
.row-infos-bien{
	padding:10px 20px;
}

.bloc-contact{
	padding:40px 0;
	margin: 50px 0;
	
	background-image:url('../img/back-contact.jpg');	
	background-repeat: no-repeat;
    background-size: cover;
    
	h2{
		margin-bottom:0;
	    text-align: center;
	    width: 100%;

	}
}

a.bien-exclusivite{
	padding-left: 20px;
    padding-right: 20px;
    height: 48px;
    margin-top: 8px;
    width:auto;
}

.row-infos-bien{
	border: 0;
	padding:0 20px;
}

.bloc-bien{
	margin: 0 0 20px;
	width: 100%;
}

.bien-prix{
	margin:0;
}

.carousel-listing-bien-exclu {
    background-color: @mainColor;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    color: #fff;
    padding: 12px 40px;
    font-size: 1.4rem;
}


.bien-prix{
	font-size:2.2rem;
	font-weight:300;
	color:#fff;
	background-color: @darkColor;
	padding:15px 20px;
	display:block;
	margin:20px auto 0;
}

.bloc-bien-textes{
	padding:20px 0;
}

.bloc-bien-img{
	width:100%;
	height:320px;
	overflow: hidden;
	position:relative;
	
	& > img{
		position: absolute;
	    left: 50%;
	    top: 50%;
	    transform: translate(-50%, -50%);	
	    //max-height: 100%;
	}

}

.bloc-bien-titre{
	font-size:1.8rem;
	color:@mainColor;
	font-weight: 600;
	text-transform:uppercase;
}

.bloc-vus{
	margin: 20px 0;	
	width: 100%;
}

.bloc-vus-img{
	width:100%;
	height:320px;
	overflow: hidden;
	position:relative;
	margin-bottom:20px;
	
	& > img{
		position: absolute;
	    left: 50%;
	    top: 50%;
	    transform: translate(-50%, -50%);	
	    max-height: 100%;
	}
}

.bloc-vus-titre{
	font-size:1.8rem;
	color:@mainColor;
	font-weight: 400;
	text-transform:uppercase;
}

.panoramique{
	position:relative;
	height: 350px;
    background-repeat: no-repeat;
    background-size: cover;
    
	& > .formSearch{
		bottom:0;
	}
}

.listing-bien{
	
}

.listing-bien-carousel{
	height:250px;
}

.listing-bien-textes{
	
}

.listing-bien-type{
	font-size:1.6rem;
	font-weight:600;
	color:@mainColor;
}
.listing-bien-ville{
	font-size:2.1rem;
	font-weight:300;
	color:@mainColor;
}
.listing-bien-categorie{
	font-size: 2.1rem;
	font-weight:300;
	color:@darkColor;
}
.listing-bien-reference{
	font-size:1.6rem;
	font-weight:300;
	display:inline-block;
}
.listing-bien-prix{
	font-size:16px;
	font-weight:600;
}


.header.fix-scroll{
	background-color: rgba(0,0,0,1);
}

.formSearch.fix-scroll{
	position: fixed;
    top: 118px;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0;
    bottom: auto;
    z-index: 20;
    
    .formSearchEntree{
    	background-color:@mainColor;
    	text-align: left;
    	padding-left: 30px;
    	&.first{
    		border-right-color:#fff;
    		text-align: right;
    		padding-right: 30px;
    	}
    }
    
    .formSearchBlocAcheter, .formSearchBlocLouer{
    	background-color: @darkColor;
    	max-width: none;
    	
    	& > form{
    		max-width: 1140px;	
    		margin-left:auto;
    		margin-right:auto;
    	}
    }
}

.bloc-adresse{
	border:1px solid @mainColor;
	padding:60px 40px;
}

.section-sells .carousel-arrow,
.section-categ .carousel-arrow,
.section-leasing .carousel-arrow{
	display:block;
    position: absolute;
    bottom: 140px;
    height: 32px;
    width: 18px;
    background-image: url(../img/arrow.png);
    z-index: 10;
    background-color: transparent;
    border: none;
    cursor:pointer;
    
    &.left{
    	left:10px;
    }
    &.right{
    	right:10px;
    	background-position-x: 18px;
    }
}
	
/* INCLUSIONS **********************/
@import "_responsive.less";
