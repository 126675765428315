
@darkColor: #232323;
@mainColor: #9f1515;

@baseFontSize: 10px;

@textFont: 'montserrat', Arial, sans-serif;
@textSize: 1.4rem;
@textLineHeight: 1.4rem;
@textColor: @darkColor;
@textStyle: normal;
@textWeight: 500;


@heightCarouselMobile: 340px;