/* DEBUT INCLUSIONS **********************/

// Bootstrap v4
@import "../../vendor/bootstrap/css/bootstrap.css";

// Librairie d'animation
@import "../../vendor/animate/animate.css";

// Librairie carousel Slick
@import "../../../vendor/slick/less/slick.less";
@import "../../../vendor/slick/less/slick-theme.less";

// Intégration des fonts (à retirer si importation via GoogleFont)
//import "_fonts.less";

// Intégration des fonts (à retirer si importation via GoogleFont)
@import "_variables.less";
@import "_surchargeBootstrap.less";
@import "_tools.less";
@import "_texts.less";
@import "_btn.less";
@import "_forms.less";
@import "_megamenu.less";


/* FIN INCLUSIONS **********************/