

a.btn, button.btn, .bien-exclusivite{
	font-family:'montserrat';
	font-size:1.5rem;
	font-weight:400;
	padding: 12px 50px;
    border-radius: 0;
}

.btn-primary, .bien-exclusivite{
	color:#fff;
	background-color:@mainColor;
	padding:15px 44px;
	text-transform: uppercase;
	border:1px solid @mainColor;
	text-align: center;
	&:focus, &:hover, &:active,&:not(:disabled):not(.disabled):active{
		background-color:@darkColor;
		border-color:@darkColor;
	}
}

a.btn-secondary, button.btn-secondary{
	color:#fff;
	background-color:transparent;
	border:1px solid #fff;
	text-transform: uppercase;
	padding-left: 50px;
    padding-right: 50px;

	
	&:focus, &:hover, &:active,&:not(:disabled):not(.disabled):active{
		background-color:#fff;
		color:@mainColor;
		border-color:#fff;
	}
}

a.btn-tertiary, button.btn-tertiary{
	color:@darkColor;
	background-color:transparent;
	border:1px solid @darkColor;
	text-transform: uppercase;
	padding-left: 50px;
    padding-right: 50px;
	margin-right: 5px;
	
	&:focus, &:hover, &:active,&:not(:disabled):not(.disabled):active{
		background-color:@darkColor;
		color:#fff;
		border-color:@darkColor;
	}
}



.btn-carousel-contact{
	position: absolute;
    z-index: 10;
    top: 43%;
    left: 50%;
    margin-left: -125px;
}
