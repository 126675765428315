
.block-center {
    margin: 0 auto;
    display: block;
}

a:focus,
button:focus{
	outline:none
}

.no-margin{margin:0!important;}
.no-padding{padding:0!important;}

.text-center{
	margin-left:auto;
	margin-right:auto;
}

@media screen and (min-width: 1200px){
	.w-10-xl{width:10%;}
	.w-20-xl{width:20%;}
	.w-30-xl{width:30%;}
	.w-40-xl{width:40%;}
	.w-50-xl{width:50%;}
	.w-60-xl{width:60%;}
	.w-70-xl{width:70%;}
	.w-80-xl{width:80%;}
	.w-90-xl{width:90%;}
	
	.mt-10-xl{margin-top:10px;}
	.mt-20-xl{margin-top:20px;}
	.mt-30-xl{margin-top:30px;}
	.mt-40-xl{margin-top:40px;}
	.mt-50-xl{margin-top:50px;}
	.mt-60-xl{margin-top:60px;}
	.mt-70-xl{margin-top:70px;}
	.mt-80-xl{margin-top:80px;}
	.mt-90-xl{margin-top:90px;}
	.mt-100-xl{margin-top:100px;}
	.mt-125-xl{margin-top:125px;}
	.mt-150-xl{margin-top:150px;}
	.mt-175-xl{margin-top:175px;}
	.mt-200-xl{margin-top:200px;}
	.mt-225-xl{margin-top:225px;}
	.mt-250-xl{margin-top:250px;}
	.mt-275-xl{margin-top:275px;}
	.mt-300-xl{margin-top:300px;}
	
	.mb-10-xl{margin-bottom:10px;}
	.mb-20-xl{margin-bottom:20px;}
	.mb-30-xl{margin-bottom:30px;}
	.mb-40-xl{margin-bottom:40px;}
	.mb-50-xl{margin-bottom:50px;}
	.mb-60-xl{margin-bottom:60px;}
	.mb-70-xl{margin-bottom:70px;}
	.mb-80-xl{margin-bottom:80px;}
	.mb-90-xl{margin-bottom:90px;}
	.mb-100-xl{margin-bottom:100px;}
	.mb-125-xl{margin-bottom:125px;}
	.mb-150-xl{margin-bottom:150px;}
	.mb-175-xl{margin-bottom:175px;}
	.mb-200-xl{margin-bottom:200px;}
	.mb-225-xl{margin-bottom:225px;}
	.mb-250-xl{margin-bottom:250px;}
	.mb-275-xl{margin-bottom:275px;}
	.mb-300-xl{margin-bottom:300px;}
	
	.pt-10-xl{padding-top:10px;}
	.pt-20-xl{padding-top:20px;}
	.pt-30-xl{padding-top:30px;}
	.pt-40-xl{padding-top:40px;}
	.pt-50-xl{padding-top:50px;}
	.pt-60-xl{padding-top:60px;}
	.pt-70-xl{padding-top:70px;}
	.pt-80-xl{padding-top:80px;}
	.pt-90-xl{padding-top:90px;}
	.pt-100-xl{padding-top:100px;}
	.pt-125-xl{padding-top:125px;}
	.pt-150-xl{padding-top:150px;}
	.pt-175-xl{padding-top:175px;}
	.pt-200-xl{padding-top:200px;}
	.pt-225-xl{padding-top:225px;}
	.pt-250-xl{padding-top:250px;}
	.pt-275-xl{padding-top:275px;}
	.pt-300-xl{padding-top:300px;}
	
	.pb-10-xl{padding-bottom:10px;}
	.pb-20-xl{padding-bottom:20px;}
	.pb-30-xl{padding-bottom:30px;}
	.pb-40-xl{padding-bottom:40px;}
	.pb-50-xl{padding-bottom:50px;}
	.pb-60-xl{padding-bottom:60px;}
	.pb-70-xl{padding-bottom:70px;}
	.pb-80-xl{padding-bottom:80px;}
	.pb-90-xl{padding-bottom:90px;}
	.pb-100-xl{padding-bottom:100px;}
	.pb-125-xl{padding-bottom:125px;}
	.pb-150-xl{padding-bottom:150px;}
	.pb-175-xl{padding-bottom:175px;}
	.pb-200-xl{padding-bottom:200px;}
	.pb-225-xl{padding-bottom:225px;}
	.pb-250-xl{padding-bottom:250px;}
	.pb-275-xl{padding-bottom:275px;}
	.pb-300-xl{padding-bottom:300px;}
	
	.px-100-xl{padding-left:100px;padding-right:100px;}
	.px-0-xl{padding-left:0px;padding-right:0px;}
}

@media screen and (max-width: 1200px){
	.w-10-lg{width:10%;}
	.w-20-lg{width:20%;}
	.w-30-lg{width:30%;}
	.w-40-lg{width:40%;}
	.w-60-lg{width:60%;}
	.w-70-lg{width:70%;}
	.w-80-lg{width:80%;}
	.w-90-lg{width:90%;}
	
	.mt-10-lg{margin-top:10px;}
	.mt-20-lg{margin-top:20px;}
	.mt-30-lg{margin-top:30px;}
	.mt-40-lg{margin-top:40px;}
	.mt-50-lg{margin-top:50px;}
	.mt-60-lg{margin-top:60px;}
	.mt-70-lg{margin-top:70px;}
	.mt-80-lg{margin-top:80px;}
	.mt-90-lg{margin-top:90px;}
	.mt-100-lg{margin-top:100px;}
	.mt-125-lg{margin-top:125px;}
	.mt-150-lg{margin-top:150px;}
	.mt-175-lg{margin-top:175px;}
	.mt-200-lg{margin-top:200px;}
	.mt-225-lg{margin-top:225px;}
	.mt-250-lg{margin-top:250px;}
	.mt-275-lg{margin-top:275px;}
	.mt-300-lg{margin-top:300px;}
	
	.mb-10-lg{margin-bottom:10px;}
	.mb-20-lg{margin-bottom:20px;}
	.mb-30-lg{margin-bottom:30px;}
	.mb-40-lg{margin-bottom:40px;}
	.mb-50-lg{margin-bottom:50px;}
	.mb-60-lg{margin-bottom:60px;}
	.mb-70-lg{margin-bottom:70px;}
	.mb-80-lg{margin-bottom:80px;}
	.mb-90-lg{margin-bottom:90px;}
	.mb-100-lg{margin-bottom:100px;}
	.mb-125-lg{margin-bottom:125px;}
	.mb-150-lg{margin-bottom:150px;}
	.mb-175-lg{margin-bottom:175px;}
	.mb-200-lg{margin-bottom:200px;}
	.mb-225-lg{margin-bottom:225px;}
	.mb-250-lg{margin-bottom:250px;}
	.mb-275-lg{margin-bottom:275px;}
	.mb-300-lg{margin-bottom:300px;}
	
	.pt-10-lg{padding-top:10px;}
	.pt-20-lg{padding-top:20px;}
	.pt-30-lg{padding-top:30px;}
	.pt-40-lg{padding-top:40px;}
	.pt-50-lg{padding-top:50px;}
	.pt-60-lg{padding-top:60px;}
	.pt-70-lg{padding-top:70px;}
	.pt-80-lg{padding-top:80px;}
	.pt-90-lg{padding-top:90px;}
	.pt-100-lg{padding-top:100px;}
	.pt-125-lg{padding-top:125px;}
	.pt-150-lg{padding-top:150px;}
	.pt-175-lg{padding-top:175px;}
	.pt-200-lg{padding-top:200px;}
	.pt-225-lg{padding-top:225px;}
	.pt-250-lg{padding-top:250px;}
	.pt-275-lg{padding-top:275px;}
	.pt-300-lg{padding-top:300px;}
	
	.pb-10-lg{padding-bottom:10px;}
	.pb-20-lg{padding-bottom:20px;}
	.pb-30-lg{padding-bottom:30px;}
	.pb-40-lg{padding-bottom:40px;}
	.pb-50-lg{padding-bottom:50px;}
	.pb-60-lg{padding-bottom:60px;}
	.pb-70-lg{padding-bottom:70px;}
	.pb-80-lg{padding-bottom:80px;}
	.pb-90-lg{padding-bottom:90px;}
	.pb-100-lg{padding-bottom:100px;}
	.pb-125-lg{padding-bottom:125px;}
	.pb-150-lg{padding-bottom:150px;}
	.pb-175-lg{padding-bottom:175px;}
	.pb-200-lg{padding-bottom:200px;}
	.pb-225-lg{padding-bottom:225px;}
	.pb-250-lg{padding-bottom:250px;}
	.pb-275-lg{padding-bottom:275px;}
	.pb-300-lg{padding-bottom:300px;}	
	
	.px-100-lg{padding-left:100px;padding-right:100px;}
	.px-0-lg{padding-left:0px;padding-right:0px;}
}

@media screen and (max-width: 992px){
	.w-10-md{width:10%;}
	.w-20-md{width:20%;}
	.w-30-md{width:30%;}
	.w-40-md{width:40%;}
	.w-60-md{width:60%;}
	.w-70-md{width:70%;}
	.w-80-md{width:80%;}
	.w-90-md{width:90%;}
	
	.mt-10-md{margin-top:10px;}
	.mt-20-md{margin-top:20px;}
	.mt-30-md{margin-top:30px;}
	.mt-40-md{margin-top:40px;}
	.mt-50-md{margin-top:50px;}
	.mt-60-md{margin-top:60px;}
	.mt-70-md{margin-top:70px;}
	.mt-80-md{margin-top:80px;}
	.mt-90-md{margin-top:90px;}
	.mt-100-md{margin-top:100px;}
	.mt-125-md{margin-top:125px;}
	.mt-150-md{margin-top:150px;}
	.mt-175-md{margin-top:175px;}
	.mt-200-md{margin-top:200px;}
	.mt-225-md{margin-top:225px;}
	.mt-250-md{margin-top:250px;}
	.mt-275-md{margin-top:275px;}
	.mt-300-md{margin-top:300px;}
	
	.mb-10-md{margin-bottom:10px;}
	.mb-20-md{margin-bottom:20px;}
	.mb-30-md{margin-bottom:30px;}
	.mb-40-md{margin-bottom:40px;}
	.mb-50-md{margin-bottom:50px;}
	.mb-60-md{margin-bottom:60px;}
	.mb-70-md{margin-bottom:70px;}
	.mb-80-md{margin-bottom:80px;}
	.mb-90-md{margin-bottom:90px;}
	.mb-100-md{margin-bottom:100px;}
	.mb-125-md{margin-bottom:125px;}
	.mb-150-md{margin-bottom:150px;}
	.mb-175-md{margin-bottom:175px;}
	.mb-200-md{margin-bottom:200px;}
	.mb-225-md{margin-bottom:225px;}
	.mb-250-md{margin-bottom:250px;}
	.mb-275-md{margin-bottom:275px;}
	.mb-300-md{margin-bottom:300px;}
	
	.pt-10-md{padding-top:10px;}
	.pt-20-md{padding-top:20px;}
	.pt-30-md{padding-top:30px;}
	.pt-40-md{padding-top:40px;}
	.pt-50-md{padding-top:50px;}
	.pt-60-md{padding-top:60px;}
	.pt-70-md{padding-top:70px;}
	.pt-80-md{padding-top:80px;}
	.pt-90-md{padding-top:90px;}
	.pt-100-md{padding-top:100px;}
	.pt-125-md{padding-top:125px;}
	.pt-150-md{padding-top:150px;}
	.pt-175-md{padding-top:175px;}
	.pt-200-md{padding-top:200px;}
	.pt-225-md{padding-top:225px;}
	.pt-250-md{padding-top:250px;}
	.pt-275-md{padding-top:275px;}
	.pt-300-md{padding-top:300px;}
	
	.pb-10-md{padding-bottom:10px;}
	.pb-20-md{padding-bottom:20px;}
	.pb-30-md{padding-bottom:30px;}
	.pb-40-md{padding-bottom:40px;}
	.pb-50-md{padding-bottom:50px;}
	.pb-60-md{padding-bottom:60px;}
	.pb-70-md{padding-bottom:70px;}
	.pb-80-md{padding-bottom:80px;}
	.pb-90-md{padding-bottom:90px;}
	.pb-100-md{padding-bottom:100px;}
	.pb-125-md{padding-bottom:125px;}
	.pb-150-md{padding-bottom:150px;}
	.pb-175-md{padding-bottom:175px;}
	.pb-200-md{padding-bottom:200px;}
	.pb-225-md{padding-bottom:225px;}
	.pb-250-md{padding-bottom:250px;}
	.pb-275-md{padding-bottom:275px;}
	.pb-300-md{padding-bottom:300px;}	
	
	.px-100-md{padding-left:100px;padding-right:100px;}
	.px-0-md{padding-left:0px;padding-right:0px;}
	
	.h-100-md{height:100%;}
	.h-200-md{height: 200px;}
	.h-300-md{height: 300px;}
}

@media screen and (max-width: 768px){
	.w-10-sm{width:10%;}
	.w-20-sm{width:20%;}
	.w-30-sm{width:30%;}
	.w-40-sm{width:40%;}
	.w-60-sm{width:60%;}
	.w-70-sm{width:70%;}
	.w-80-sm{width:80%;}
	.w-90-sm{width:90%;}
	
	.mt-10-sm{margin-top:10px;}
	.mt-20-sm{margin-top:20px;}
	.mt-30-sm{margin-top:30px;}
	.mt-40-sm{margin-top:40px;}
	.mt-50-sm{margin-top:50px;}
	.mt-60-sm{margin-top:60px;}
	.mt-70-sm{margin-top:70px;}
	.mt-80-sm{margin-top:80px;}
	.mt-90-sm{margin-top:90px;}
	.mt-100-sm{margin-top:100px;}
	.mt-125-sm{margin-top:125px;}
	.mt-150-sm{margin-top:150px;}
	.mt-175-sm{margin-top:175px;}
	.mt-200-sm{margin-top:200px;}
	.mt-225-sm{margin-top:225px;}
	.mt-250-sm{margin-top:250px;}
	.mt-275-sm{margin-top:275px;}
	.mt-300-sm{margin-top:300px;}
	
	.mb-10-sm{margin-bottom:10px;}
	.mb-20-sm{margin-bottom:20px;}
	.mb-30-sm{margin-bottom:30px;}
	.mb-40-sm{margin-bottom:40px;}
	.mb-50-sm{margin-bottom:50px;}
	.mb-60-sm{margin-bottom:60px;}
	.mb-70-sm{margin-bottom:70px;}
	.mb-80-sm{margin-bottom:80px;}
	.mb-90-sm{margin-bottom:90px;}
	.mb-100-sm{margin-bottom:100px;}
	.mb-125-sm{margin-bottom:125px;}
	.mb-150-sm{margin-bottom:150px;}
	.mb-175-sm{margin-bottom:175px;}
	.mb-200-sm{margin-bottom:200px;}
	.mb-225-sm{margin-bottom:225px;}
	.mb-250-sm{margin-bottom:250px;}
	.mb-275-sm{margin-bottom:275px;}
	.mb-300-sm{margin-bottom:300px;}
	
	.pt-10-sm{padding-top:10px;}
	.pt-20-sm{padding-top:20px;}
	.pt-30-sm{padding-top:30px;}
	.pt-40-sm{padding-top:40px;}
	.pt-50-sm{padding-top:50px;}
	.pt-60-sm{padding-top:60px;}
	.pt-70-sm{padding-top:70px;}
	.pt-80-sm{padding-top:80px;}
	.pt-90-sm{padding-top:90px;}
	.pt-100-sm{padding-top:100px;}
	.pt-125-sm{padding-top:125px;}
	.pt-150-sm{padding-top:150px;}
	.pt-175-sm{padding-top:175px;}
	.pt-200-sm{padding-top:200px;}
	.pt-225-sm{padding-top:225px;}
	.pt-250-sm{padding-top:250px;}
	.pt-275-sm{padding-top:275px;}
	.pt-300-sm{padding-top:300px;}
	
	.pb-10-sm{padding-bottom:10px;}
	.pb-20-sm{padding-bottom:20px;}
	.pb-30-sm{padding-bottom:30px;}
	.pb-40-sm{padding-bottom:40px;}
	.pb-50-sm{padding-bottom:50px;}
	.pb-60-sm{padding-bottom:60px;}
	.pb-70-sm{padding-bottom:70px;}
	.pb-80-sm{padding-bottom:80px;}
	.pb-90-sm{padding-bottom:90px;}
	.pb-100-sm{padding-bottom:100px;}
	.pb-125-sm{padding-bottom:125px;}
	.pb-150-sm{padding-bottom:150px;}
	.pb-175-sm{padding-bottom:175px;}
	.pb-200-sm{padding-bottom:200px;}
	.pb-225-sm{padding-bottom:225px;}
	.pb-250-sm{padding-bottom:250px;}
	.pb-275-sm{padding-bottom:275px;}
	.pb-300-sm{padding-bottom:300px;}	
	
	.px-100-sm{padding-left:100px;padding-right:100px;}
	.px-0-sm{padding-left:0px;padding-right:0px;}
	
	.h-100-sm{height:100%;}
	.h-200-sm{height: 200px;}
	.h-300-sm{height: 300px;}
	
}

@media screen and (max-width: 576px){
	.w-10{width:10%;}
	.w-20{width:20%;}
	.w-30{width:30%;}
	.w-40{width:40%;}
	.w-60{width:60%;}
	.w-70{width:70%;}
	.w-80{width:80%;}
	.w-90{width:90%;}
	
	.mt-10{margin-top:10px;}
	.mt-20{margin-top:20px;}
	.mt-30{margin-top:30px;}
	.mt-40{margin-top:40px;}
	.mt-50{margin-top:50px;}
	.mt-60{margin-top:60px;}
	.mt-70{margin-top:70px;}
	.mt-80{margin-top:80px;}
	.mt-90{margin-top:90px;}
	.mt-100{margin-top:100px;}
	.mt-125{margin-top:125px;}
	.mt-150{margin-top:150px;}
	.mt-175{margin-top:175px;}
	.mt-200{margin-top:200px;}
	.mt-225{margin-top:225px;}
	.mt-250{margin-top:250px;}
	.mt-275{margin-top:275px;}
	.mt-300{margin-top:300px;}
	
	.mb-10{margin-bottom:10px;}
	.mb-20{margin-bottom:20px;}
	.mb-30{margin-bottom:30px;}
	.mb-40{margin-bottom:40px;}
	.mb-50{margin-bottom:50px;}
	.mb-60{margin-bottom:60px;}
	.mb-70{margin-bottom:70px;}
	.mb-80{margin-bottom:80px;}
	.mb-90{margin-bottom:90px;}
	.mb-100{margin-bottom:100px;}
	.mb-125{margin-bottom:125px;}
	.mb-150{margin-bottom:150px;}
	.mb-175{margin-bottom:175px;}
	.mb-200{margin-bottom:200px;}
	.mb-225{margin-bottom:225px;}
	.mb-250{margin-bottom:250px;}
	.mb-275{margin-bottom:275px;}
	.mb-300{margin-bottom:300px;}
	
	.pt-10{padding-top:10px;}
	.pt-20{padding-top:20px;}
	.pt-30{padding-top:30px;}
	.pt-40{padding-top:40px;}
	.pt-50{padding-top:50px;}
	.pt-60{padding-top:60px;}
	.pt-70{padding-top:70px;}
	.pt-80{padding-top:80px;}
	.pt-90{padding-top:90px;}
	.pt-100{padding-top:100px;}
	.pt-125{padding-top:125px;}
	.pt-150{padding-top:150px;}
	.pt-175{padding-top:175px;}
	.pt-200{padding-top:200px;}
	.pt-225{padding-top:225px;}
	.pt-250{padding-top:250px;}
	.pt-275{padding-top:275px;}
	.pt-300{padding-top:300px;}
	
	.pb-10{padding-bottom:10px;}
	.pb-20{padding-bottom:20px;}
	.pb-30{padding-bottom:30px;}
	.pb-40{padding-bottom:40px;}
	.pb-50{padding-bottom:50px;}
	.pb-60{padding-bottom:60px;}
	.pb-70{padding-bottom:70px;}
	.pb-80{padding-bottom:80px;}
	.pb-90{padding-bottom:90px;}
	.pb-100{padding-bottom:100px;}
	.pb-125{padding-bottom:125px;}
	.pb-150{padding-bottom:150px;}
	.pb-175{padding-bottom:175px;}
	.pb-200{padding-bottom:200px;}
	.pb-225{padding-bottom:225px;}
	.pb-250{padding-bottom:250px;}
	.pb-275{padding-bottom:275px;}
	.pb-300{padding-bottom:300px;}
	
	.px-100{padding-left:100px;padding-right:100px;}
	.px-0{padding-left:0px;padding-right:0px;}
	
	.h-100{height:100%;}
	.h-200{height: 200px;}
	.h-300{height: 300px;}
}

.img-full{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width:100%;
}

.min-w-100 {
	min-width: 100%;
}



.back-main{
	background-color: @mainColor!important;
	
	&.btn{
		border-color:@mainColor!important;
	}
}

.back-dark{
	background-color: @darkColor;
}


.image-center{
	background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}