
/* TEXTES **********************/

body, p{
	font-family: @textFont;
	font-size: @textSize;
	color: @textColor;
	font-style: @textStyle;
	font-weight: @textWeight;
	line-height: @textLineHeight;
}

p, .label-filtre{
	font-size:1.6rem;
	margin-bottom:30px;
	line-height: 1.4;
	font-weight:300;
	
	& > a{
		color:@mainColor;
		text-decoration: underline;
		
		&:hover{
			color:@mainColor;
			text-decoration: none;
		}
	}
}

.label-filtre{
	margin:0 20px 0 0;
}

h1{
	color:@mainColor;
	font-size: 2.5rem;
	font-weight:300;
	text-transform: uppercase;
	margin-bottom: 4rem;
	
}

.bien-titre{
		font-size:2rem;
		font-weight:600;
		margin-bottom: 0;
		margin-top: 16px;
		text-align:center;
	}
	
.bien-titre-ville{
	font-weight:300;	
}

.bien-titre-ville{
	font-weight:300;
	color:@darkColor;
}

h2{
	position:relative;
	font-size: 2.8rem;
	font-weight:300;
	text-transform: uppercase;
	display:inline-block;
}



.titre-bloc-couleur {
	position:relative;
	font-size: 2.8rem;
	font-weight:300;
	text-transform: uppercase;
	display:inline-block;	
}

.listing-bien-titre {
    text-transform: capitalize;
    font-size: 2rem;
    margin-bottom: 10px;
    & > span{
    	color:@mainColor;	
    }
}

h3{
	position: relative;
	font-size: 1.7rem;
	font-weight:600;
	text-transform: uppercase;
	margin-bottom: 2rem;
}

h4{
	color:@mainColor;
	font-size:1.7rem;
	font-weight:300;
	text-transform: uppercase;
}
	
p.m-auto{
    margin: 0 auto 30px;
}

.underline{
	display:block;
	width:72px;
	height:1px;
	background-color:#fff;
	position: absolute;
	bottom: -12px;
    &.right{
	    right: -20px;	
    }
    &.left{
	    left: -20px;
    }
    
    &.dark{
    	background-color:@darkColor;
    }
    
}

.text-footer p{
	font-size:1.6rem;
	line-height: 2.4rem;
	margin-bottom: 10px;
	font-weight: 200;
}

.text-subfooter p, .text-subfooter a{
	font-size:1.4rem;
	font-weight: 200;
	
}

.nav-footer a{
	font-size:1.4rem;
	font-weight: 300;
	color:#fff;
	margin: 0 10px;
}

.ico-reseau{
	display:inline-block;
	height: 40px;
	width: 70px;
	
	& > svg {
	    width: 100%;
	    height: 100%;
	}
	
	&:hover > svg > path{
	//	stroke: @mainColor!important;
	}
}



.bien-reference{
	color:lighten(@darkColor, 20%);
	font-size:1.7rem;
	font-weight:300;
	font-style: italic;
	text-align:center;
	width: 100%;
	display: block;
}

.bien-prestation{
	font-weight:300;
	font-size:1.8rem;
	color:@darkColor;
}

.bloc-adresse-titre{
	font-size: 2.4rem;
	text-transform: uppercase;
	font-weight:300;
	color: @darkColor;
}

.bloc-adresse-ville{
	color: @mainColor;
}

.bloc-adresse-coordonnees, .bloc-tels{
	font-size:1.7rem;
	font-weight:600
}

.bloc-tels > svg{
	width: 26px;
    display: inline;
    margin-right: 10px;
}