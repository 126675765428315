
/* FORM **********************/

input, button, a, select, textarea{
	&:active, &:focus{
		outline:none!important;
		box-shadow: none!important;
		
		&::placeholder{
			opacity:0;
		}
	}
}
		

.form-control{
	font-family:'montserrat';
	font-size:1.5rem;
	font-weight:400;
	padding: 12px;
    border-radius: 0;
    border: 0;
    color:@darkColor;
    
    &::placeholder{
		font-family:'montserrat';
		font-size:1.5rem;
		color:@darkColor;
		font-weight:400;
	}
	
	&.dark{
		border-bottom:1px solid @darkColor;	
		padding:12px 0;
	}
}


.form-filtre {
    border: 1px solid #282828;
    padding: 17px;
    width: auto;
    color:@darkColor;
}


.containerCheckbox {
	display: block;
	position: relative;
	margin-top: 10px;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	padding-top: 5px;
	    
	    
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	
	font-family: 'montserrat';
	font-size:1.7rem;
	font-weight:400;
	color:#fff;
	
	&.dark{
		color:@darkColor;
	}
}

/* Hide the browser's default checkbox */
.containerCheckbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #fff;
	.dark &{
		border: 1px solid @darkColor;
	}
}

/* On mouse-over, add a grey background color */
.containerCheckbox:hover input ~ .checkmark {
	background-color: @mainColor;
}

/* When the checkbox is checked, add a blue background */
.containerCheckbox input:checked ~ .checkmark {
	background-color: @mainColor;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.containerCheckbox input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.containerCheckbox .checkmark:after {
	left: 8px;
	top: 5px;
	width: 7px;
	height: 12px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}


.form-bloc-newsletter{
	input{
		border:1px solid @darkColor;
	}
	
}

.input-transparent,
.textarea-transparent{
	background:transparent;
	border-bottom:1px solid #fff;
	padding:10px 0;
	margin:10px 0;
	
	&:focus{
		outline:none;
		background:transparent;
		border-bottom-color: @mainColor;
	}
	
	&.text-white::placeholder{
		color:#fff;
	}
	
	&.text-black{
		border-bottom:1px solid @darkColor;	
		&:focus{
			border-bottom-color: @mainColor;
		}
	}
}

.textarea-transparent{
	border:1px solid #fff;	
	padding:20px;
	height: 203px;
	&:focus{
		border-color:#fff;
	}
	
	&.text-black{
		border-color:@darkColor;
		&:focus{
			border-color: @mainColor;
		}
	}
}

.textarea-contact{
	height:300px;
}

