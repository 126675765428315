
/* SURCHARGE BOOTSTRAP **********************/

.nav-link{
	padding: 1.2rem 1.5rem;
}

.carousel-indicators{
	bottom:75px;	
}

select.form-control-lg:not([size]):not([multiple]), select.form-control:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]), .input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]), .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]), .input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]){
	height:auto;	
}



.dropdown-toggle::after{
	display:none;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 1);
    border-color: rgba(255, 255, 255, 0.1);
    font-size: 2rem;
    margin: 10px;
}

.navbar-dark .navbar-nav .nav-link{
	color:#fff;	
}

.carousel-control-next-icon{
	background-image: url(../img/next.png);
    height: 37px;
    width: 14px;
    background-position-x: 10px;
    background-size: auto;
    position:relative;
    opacity:1;
   
}

.carousel-control-prev-icon{
	background-image: url(../img/prev.png);
    height: 37px;
    width: 14px;
    background-position-x: 6px;
    background-size: auto;	
    opacity:1;
}
