
/* MEGAMENU **********************/

.header{
    position: fixed;
	width:100%;
    background: rgba(0,0,0,0.6);
    z-index: 100;
}



.backDropDown{
	width: 100%;
    height: 100px;
    position: absolute;
    top: 100%;
    background-color: rgba(255,255,255,0.9);
    display:none;
    z-index: 10;
}

.navbar{
	padding:0 1rem;
}

.dropdown-menu{
	background:transparent;
    border: 0;
    border-radius: 0;
    padding: 25px 0;
   
	&.megamenu{
		column-count: 3;
    	column-gap: 40px;
	}

}

.nav-item{
	padding: 0 1rem;
	&.active{
		color:#fff;
	}	
	
	&.select-language {
	    padding: 0;
	}
}

.nav-link{
	color:#fff;
	font-size:1.5rem;
	text-transform: uppercase;
	font-family: 'montserrat';
	font-weight:300;
	
	&:focus, &:active, &:hover{
		color:#fff;
	}
}

.dropdown-toggle[aria-expanded="true"]{
	position:relative;
	&:after{	
		content: '';
	    display: block;
	    width: 0;
	    height: 0;
	    border-style: solid;
	    border-width: 12px 9px 0 9px;
	    border-color: #000000 transparent transparent transparent;
	    position: absolute;
	    top: 100%;
	    left: 50%;
	    margin-left: -9px;
	    z-index: 1001;
	}
}

.dropdown-item{
	color:@darkColor;
	font-size:1.5rem;
	font-family: 'montserrat';
	font-weight:400;	
	padding: 0.7rem;
	
	&:hover{
		background:none;
		text-decoration:underline;
	}
	
	&:active, &:focus{
		color:@darkColor;
	}
}
